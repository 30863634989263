<template>
  <section>
    <h2 class="uppercase pl-4 lg:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Rollladen / Vorbaurolladen <br />
    </h2>
    <article class="container mx-auto p-4 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Heroal Vorbaurollladen
      </h2>
      <div class="grid grid-cols-2">
        <div class="row-span-4 col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>
        <div class="col-span-full lg:col-span-1">
          <h3
            class="text-2xl lg:pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Vorbaurollladen
          </h3>
          <p class="px-12 w-full md:pl-10 md:pb-3">
            Unsere Vorbaurollladen montieren wir wie der Name schon sagt, vor
            Ihrem Fenster. Dabei gibt es verschiedene und extra auf Ihren Wunsch
            angepasste Ausführungen. Dabei können sie entscheiden, ob Sie den
            Kasten in Rund oder eckig haben wollen. Bekommen potentielle
            Einbrecher Ihr Vorbaurollladen zu sehen, werden sie in den meisten
            fällen abgeschreckt, da sie einbruchshemmend sind. <br /><br />
            Auf Wunsch werden die Ausführungsformen ebenfalls für Sie
            angefertigt, z.B. Schrägrollladen <br /><br />
            Ihre Vorteile: <br /><br />
          </p>
          <div class="px-12">
            <ul class="flex flex-row">
              <li class="mx-1"><i class="fas fa-check"></i></li>
              <li>Schnelle Montage und einfache Wartung<br /></li>
            </ul>
            <ul class="flex flex-row">
              <li class="mx-1"><i class="fas fa-check"></i></li>
              <li>Bei Neubauten ein Hingucker<br /></li>
            </ul>
            <ul class="flex flex-row">
              <li class="mx-1"><i class="fas fa-check"></i></li>
              <li>Architektonische Akzente<br /></li>
            </ul>
            <ul class="flex flex-row">
              <li class="mx-1"><i class="fas fa-check"></i></li>
              <li>Kann nachträglich eingebaut werden<br /></li>
            </ul>
            <ul class="flex flex-row">
              <li class="mx-1"><i class="fas fa-check"></i></li>
              <li>Integrierter Insektenschutzrollo<br /></li>
            </ul>
            <ul class="flex flex-row">
              <li class="mx-1"><i class="fas fa-check"></i></li>
              <li>Verschiedene Ausführungen und Farben erhältlich<br /></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-span-full">
        <div
          class="text-2xl lg:pl-5 py-10 md:pl-10 md:pb-3"
          style="color: #033859"
        >
          Heroal Vorbaurollläden bestechen durch erhöhte Sicherheit, maximalen
          Komfort und höchste Funktionalität
        </div>
        <div class="px-2 w-full md:pl-10 md:pb-3">
          Eckige heroal Vorbau-Blendensysteme sorgen in jeder Einbausituation
          für eine geradlinige Gestaltungsvariantenmit klaren Konturen. Durch
          die Ausführungsvarianten in 20°- und 45°-Optik kann ein hoher
          Lichteinfall erreicht werden. Ihre einfache und schnelle Montage in
          die Fensterlaibung oder vor die Fassade machen sie zu einem idealen
          System für die Nachrüstung oder Renovierung.<br /><br />
          Das Serienprogramm bietet neben rollgeformten Vorbau- auch
          stranggepresste Blendensysteme, die für ein zusätzliches Plus an
          Stabilität und Sicherheit sorgen.
        </div>
      </div>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="Vorteile">
            <div class="text-lg">
              <span class="font-bold">Energiesparen</span> <br />
              Das Vorbau-Blendsystem wird bauseitig in die Fensterlaibung oder
              vor die Fassade montiert, so dass keine Hohlräume und damit auch
              keine Wärmebrücken entstehen. Damit verbessert sich die
              Wärmedämmung des Gebäudes deutlich und spart so Heizenenergie ein.
            </div>
            <div class="text-lg">
              <span class="font-bold">Schallschutz</span> <br />
              Bei den komplett außenliegenden Vorbau-Blendensystemen sind
              schallbrücken ausgeschlossen - das sorgt für ein komfortableres
              Wohnen und effektiveres Arbeiten.
            </div>
          </tab>
          <tab title="Extras">
            <div class="text-lg">
              Heroal - Vorbaurollladen sind in verschiedenen Ausführungen
              erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/heroal/vorbau/heroal_Vorbau-Blenden-eckig.webp"),
          alt: "heroal vorbaurolladen",
        },
        {
          image: require("@/assets/img/heroal/vorbau/heroal_Vorbau-Blenden-rund.webp"),
          alt: "vorbaurolladen hoch",
        },
        {
          image: require("@/assets/img/heroal/vorbau/heroal-gruen.webp"),
          alt: "vorbaurolladen garagentor",
        },
        {
          image: require("@/assets/img/heroal/vorbau/heroal-rollladen.webp"),
          alt: "vorbaurolladen fenster",
        },
      ],
    };
  },
};
</script>
